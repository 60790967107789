import React, { useEffect } from 'react';
import './App.css';

const App = () => {
  useEffect(() => {
    const iframe = document.getElementById('wix-iframe');

    const handleIframeLoad = () => {
      iframe.contentWindow.postMessage({ action: 'hideAds' }, 'https://idanlevi2.wixsite.com');
    };

    const updateIframeDimensions = () => {
      iframe.style.width = `${window.innerWidth}px`;
      iframe.style.height = `${window.innerHeight}px`;
    };

    iframe.onload = handleIframeLoad;
    window.addEventListener('resize', updateIframeDimensions);
    updateIframeDimensions();

    return () => {
      window.removeEventListener('resize', updateIframeDimensions);
    };
  }, []);

  return (
    <div className="App">
      <iframe
        src="https://idanlevi2.wixsite.com/levilop"
        title="Embedded Wix Site"
        className="embedded-wix"
        id="wix-iframe"
        style={{ width: '100%', height: '100%', border: 'none', margin: 0, padding: 0 }}
      />
    </div>
  );
};

export default App;
